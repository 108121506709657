import React from "react"
import OrdersList from "../components/UI/OrdersList/OrdersList"
import Layout from "../components/Layout"
import { Container} from "react-bootstrap"
import * as styles from "./orders.module.scss"

const PageOrders = () => {

  return (
    <Layout
      staticSeo={{ seoTitle: "Orders", seoDescription: "My orders" }}
      location={{ pathname: "orders" }}
    >
      <Container className={`${styles.container} pt-5`}>
        <h2>My Orders</h2>
        <hr/>
        <OrdersList className="pt-5" />
      </Container>
    </Layout>
  )
}

export default PageOrders