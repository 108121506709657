import React, { useEffect } from 'react';
import * as PropTypes from 'prop-types';
import {connect} from 'react-redux';
import { createStructuredSelector } from "reselect"
import {ordersOperations, ordersSelectors} from '../../../state/orders'
import { parseIsoDatetime, formatDateToLocalTime } from "../../../utils/dateUtils"

const OrdersList = ({orders, isError, fetchOrders, isFetchingOrders, className}) => {

  useEffect(()=> {
    fetchOrders();
  },[])

  if(isFetchingOrders){
    return (
      <div className={className}>Loading data...</div>
    )
  }

  if(isError){
    return (
      <div className={className}>Cannot fetch list of orders due to connection problems</div>
    )
  }

  return (
    <div className={className}>
      <div className="Rtable Rtable--4cols Rtable--collapse">
        <div className="Rtable-cell Rtable-cell--head"><strong>No. order</strong></div>
        <div className="Rtable-cell Rtable-cell--head"><strong>Total price</strong></div>
        <div className="Rtable-cell Rtable-cell--head"><strong>Status</strong></div>
        <div className="Rtable-cell Rtable-cell--head"><strong>Created at</strong></div>
        {orders.map((order, index) =>(
          <>
            <div key={`col-1-${index}`} className="Rtable-cell">{order.number}</div>
            <div key={`col-2-${index}`} className="Rtable-cell">{order.total} {order.currency}</div>
            <div key={`col-3-${index}`} className="Rtable-cell">{order.status}</div>
            <div key={`col-4-${index}`} className="Rtable-cell">{formatDateToLocalTime(parseIsoDatetime(order.date_created))}</div>
          </>
        ))}
      </div>
    </div>
  );

}

const mapStateToProps = createStructuredSelector({
  orders: ordersSelectors.getOrders,
  isError: ordersSelectors.isError,
  isFetchingOrders: ordersSelectors.isFetchingOrders,
});

const mapDispatchToProps = {
  fetchOrders: ordersOperations.fetchOrders,
};

OrdersList.propTypes = {
  orders: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
  })).isRequired,
  isError: PropTypes.bool.isRequired,
  isFetchingOrders: PropTypes.bool.isRequired,
  fetchOrders: PropTypes.func.isRequired,
  className: PropTypes.string,
};

OrdersList.defaultProps = {
  className: ""
}

export default connect(mapStateToProps, mapDispatchToProps) (OrdersList);
